import { gql } from '@apollo/client';

export const readTerminal = gql`
  query readTerminal($terminalId: String!) {
    readTerminal(terminalId: $terminalId) {
      terminal {
        _id
        reference {
          unique
          custom
        }
        address {
          streetNumber
          streetName
          city
        }
        statistics {
          label
          value
        }
        coordinates
      }
    }
  }
`;
