import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { readTransaction } from './gql/readTransaction.gql';

const initialContext = {
  readTransaction: undefined,
};

const transactionsDetailsMachine = createMachine(
  {
    id: 'transactions',
    initial: 'idle',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      idle: {
        on: {
          READ_TRANSACTION: {
            target: 'readTransaction',
          },
          RESET: {
            actions: assign({
              readTransaction: () => undefined,
            }),
          },
        },
      },
      readTransaction: {
        invoke: {
          id: 'readTransaction',
          src: 'readTransaction',
          onDone: {
            target: 'idle',
            actions: assign({
              readTransaction: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('alarms KILLED');
      },
      logWakeup: () => {
        console.log(`alarms WAKEUP`);
      },
    },
    services: {
      readTransaction: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: readTransaction,
        }),
    },

    guards: {
      ready: () => true,
    },
  },
);

export const transactionsDetailsService = interpret(
  transactionsDetailsMachine,
).start();
