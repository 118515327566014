import { RouterType, dispatch } from '@gimlite/router';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Maps } from '@gimlite/watermelon/components/maps/maps.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { PagingInType } from '@gimlite/watermelon/components/pagination/pagination.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { MachineDetails } from '../../components/machine-details/machine-details';
import { zoneDetailsService } from '../../components/select-zone/select-zone.machine';
import { searchSystemsDataNode } from '../../data/system';

type TerminalStatistic = {
  label: string;
  value: string;
};

type Terminal = {
  _id: string;
  reference: {
    unique: string;
    custom?: string;
  };
  address: {
    streetNumber: number;
    streetName: string;
    city: string;
  };
  statistics?: TerminalStatistic[];
  coordinates: [number, number];
};

export const HomePage = ({ machine }: RouterType.Legacy) => {
  const { isDesktop } = useDevice();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const resumeAlarms = useSelector(
    machine,
    ({ context }: any) => context.resumeAlarms,
  );

  const searchTerminals = useSelector(
    machine,
    ({ context }: any) => context.searchTerminals,
  ) as { list: Terminal[] | undefined; paging: PagingInType };

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  const [selectedId, setSelectedId] = useState<(string | number)[]>([]);

  const markers = useMemo(() => {
    if (!searchTerminals?.list) return null;
    return searchTerminals?.list.map(
      ({ _id, coordinates, reference: { unique, custom } }) => {
        return {
          id: _id,
          location: {
            lat: coordinates[0],
            lng: coordinates[1],
          },
          label: `${unique} ⋅ ${custom}`,
          markerStyleId: 'default',
        };
      },
    );
  }, [searchTerminals]);

  useEffect(() => {
    if (selectedId && selectedId.length > 0) {
      setIsOpen(() => true);
    }
  }, [selectedId]);

  useEffect(() => {
    dispatch('SEARCH_TERMINAL', { payload: { zoneId } });
  }, [zoneId]);

  const resumeStateSystemsNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: 'System status',
          subtitle: 'Summary of states of life',
          responsive: {
            padding: false,
          },
        }}
      >
        <ListFlatInfo<any>
          data={{ list: searchSystemsDataNode }}
          config={{
            columns: [
              { key: 'present', size: 'min-content' },
              { key: 'identity', size: '1fr' },
            ],
            yMax: 'min-content',
            pagination: 'none',
          }}
        ></ListFlatInfo>
      </Widget>
    );
  }, [isDesktop, searchSystemsDataNode]);

  const resumeAlarmsNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: 'Category balance sheet',
          subtitle: 'Criticality level statistics',
          responsive: {
            padding: false,
          },
        }}
      >
        <ListFlatInfo<any>
          data={resumeAlarms}
          config={{
            columns: [
              { key: 'present', size: 'min-content' },
              { key: 'identity', size: '1fr' },
            ],
            yMax: isDesktop ? 'initial' : 'min-content',
            pagination: 'none',
          }}
        ></ListFlatInfo>
      </Widget>
    );
  }, [isDesktop, resumeAlarms]);

  const terminalsMapNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: searchTerminals
            ? `Maps of machines (${searchTerminals.paging.count})`
            : `Maps of machines`,
          subtitle: 'Find all the machines in the current sector',
          responsive: {
            padding: false,
          },
        }}
      >
        {markers && (
          <Maps
            config={{
              selectionScaleFactor: 2,
              yMax: isDesktop ? '100%' : '100dvh',
            }}
            data={{
              markers: markers as any,
              selected: selectedId,
            }}
            handleEvent={{
              select: (markerId) => setSelectedId([markerId]),
              unselect: () => setSelectedId([]),
            }}
          />
        )}
      </Widget>
    );
  }, [isDesktop, markers]);

  useEffect(() => {
    dispatch('RESUME_ALARMS', {
      payload: {
        type: '*',
        zoneId,
      },
    });
  }, [zoneId]);

  useEffect(() => {
    if (resumeAlarms) {
      dispatch('SEARCH_TERMINALS', {
        payload: {
          type: '*',
          zoneId,
        },
      });
    }
  }, [resumeAlarms]);

  useEffect(() => {
    if (selectedId && selectedId.length > 0) {
      setIsOpen(() => true);
    }
  }, [selectedId]);

  return (
    <Page>
      {isDesktop ? (
        <Zone
          config={{
            zones: [
              ['system', 'map'],
              ['alarms', 'map'],
            ],
            rows: ['min-content', '1fr'],
            columns: ['1fr', '2fr'],
          }}
        >
          <Zone.Area config={{ area: 'system' }}>
            {resumeStateSystemsNode}
          </Zone.Area>
          <Zone.Area config={{ area: 'alarms' }}>{resumeAlarmsNode}</Zone.Area>
          <Zone.Area config={{ area: 'map' }}>{terminalsMapNode}</Zone.Area>
        </Zone>
      ) : (
        <Col>
          {resumeStateSystemsNode}
          <Space config={{ count: 5, way: 'vertical' }}></Space>
          {resumeAlarmsNode}
          <Space config={{ count: 5, way: 'vertical' }}></Space>
          {terminalsMapNode}
        </Col>
      )}

      <MachineDetails
        handleEvent={{
          close: () => {
            setIsOpen(() => false);
            setSelectedId([]);
          },
        }}
        config={{ isOpen: isOpen }}
        data={{
          terminalId: selectedId?.[0] ? `${selectedId?.[0]}` : undefined,
        }}
      ></MachineDetails>
    </Page>
  );
};
