import { Checkbox } from '@gimlite/watermelon/components/checkbox/checkbox.component'
import { Drawer } from '@gimlite/watermelon/components/drawer/drawer.component'
import { Form } from '@gimlite/watermelon/components/form/form.component'
import { Icon } from '@gimlite/watermelon/components/icon/icon.component'
import { Popover } from '@gimlite/watermelon/components/popover/popover.component'
import {
  Radio,
  RadioGroup
} from '@gimlite/watermelon/components/radio/radio.component'
import { Search } from '@gimlite/watermelon/components/search/search.component'
import { Widget } from '@gimlite/watermelon/components/widget/widget.component'
import { Write } from '@gimlite/watermelon/components/write/write.component'
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook'
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './search-tools.component.scss'

class Static {
  static buildTriggerNode = ({
    title,
    type,
    currentSelectText
  }: {
    title: SearchToolsType.Config.Action['title']
    type: SearchToolsType.Static.Type
    currentSelectText: SearchToolsType.Static.ActionItem[]
  }) => {
    let valueText = ''

    if (type === 'single') valueText = `${currentSelectText[0].label}`

    switch (currentSelectText.length) {
      case 0:
        valueText = `${'All'}`
        break
      case 1:
        valueText = `${currentSelectText[0].label}`
        break

      default:
        valueText = `${currentSelectText.length} ${'selected'}`
        break
    }

    return (
      <button className="search-tools__more__select">
        <span className="search-tools__more__select__text">
          {`${title} : ${valueText}`}
        </span>
        <Icon
          className="search-tools__more__select__icon"
          config={{ type: 'faChevronDownSolid' }}
        ></Icon>
      </button>
    )
  }

  static buildFormNode = ({
    key,
    title,
    subtitle,
    type,
    items,
    currentSelectForm,
    oldActionState,
    newActionState
  }: {
    key: SearchToolsType.Config.Action['key']
    title: SearchToolsType.Config.Action['title']
    subtitle?: SearchToolsType.Config.Action['subtitle']
    items: SearchToolsType.Config.Action['items']
    type: SearchToolsType.Static.Type
    currentSelectForm: Record<string, boolean> | {}
    oldActionState: SearchToolsType.Static.ActionState
    newActionState: (value: SearchToolsType.Static.ActionState) => void
  }) => {
    return (
      <div>
        <Widget
          config={{
            title: title,
            subtitle: subtitle
          }}
        >
          <Form
            data={{
              value: currentSelectForm
            }}
            handleEvent={{
              change: (newValue) => {
                const newAction = oldActionState

                const findAction = oldActionState.findIndex(
                  (action) => action.title === title
                )

                newAction[findAction].currentSelectForm = newValue
                newAction[findAction].currentSelectText = Static.decodeForm({
                  key,
                  type,
                  items,
                  currentSelectForm: newValue
                })

                newActionState(newAction)
              }
            }}
          >
            <div className="search-tools-actions">
              {type === 'multiple' ? (
                items.map(({ label }, index) => {
                  return (
                    <div
                      key={`search-tools-actions__line-${index}`}
                      className="search-tools-actions__line"
                    >
                      <Form.Item
                        config={{ name: `${key}-multiple|-|${index}` }}
                      >
                        <Checkbox config={{ size: 'xlarge' }}></Checkbox>
                      </Form.Item>
                      <Write
                        className="search-tools-actions__line__label"
                        data={{ item: label }}
                        config={{ mode: 'value-medium' }}
                      ></Write>
                    </div>
                  )
                })
              ) : (
                <Form.Item config={{ name: `${key}-single` }}>
                  <RadioGroup config={{ way: 'vertical' }}>
                    {items.map(({ value, label }, index) => (
                      <Radio
                        key={`radio-${index}`}
                        data={{
                          label,
                          value
                        }}
                      ></Radio>
                    ))}
                  </RadioGroup>
                </Form.Item>
              )}
            </div>
          </Form>
        </Widget>
      </div>
    )
  }

  static encodeForm = ({
    key,
    type,
    items,
    currentSelectText
  }: {
    key: SearchToolsType.Config.Action['key']
    type: SearchToolsType.Static.Type
    items: SearchToolsType.Config.Action['items']
    currentSelectText: SearchToolsType.Static.ActionItem[]
  }) => {
    const formattedSelect = (
      Array.isArray(currentSelectText)
        ? currentSelectText.length !== 0
          ? currentSelectText
          : []
        : [currentSelectText]
    ).filter(({ value }) => value)

    const encode = formattedSelect.reduce(
      (
        acc: Record<string, string> | {},
        { value }: SearchToolsType.Static.ActionItem
      ) => {
        if (type === 'multiple') {
          const tryFindInRef = items.findIndex(
            ({ value: valueItem }) => valueItem === value
          )
          return {
            ...acc,
            [`${key}-${type}|-|${tryFindInRef}`]: tryFindInRef !== -1
          }
        } else {
          return { ...acc, [`${key}-single`]: value }
        }
      },
      {}
    )

    return encode
  }

  static decodeForm = ({
    key,
    type,
    items,
    currentSelectForm
  }: {
    key: SearchToolsType.Config.Action['key']
    type: SearchToolsType.Static.Type
    items: SearchToolsType.Config.Action['items']
    currentSelectForm: Record<string, boolean> | {}
  }): SearchToolsType.Static.ActionItem[] => {
    const decode = Object.entries(currentSelectForm).reduce(
      (
        acc: SearchToolsType.Static.ActionItem[],
        [key, value]: [string, string | boolean]
      ) => {
        if (type === 'single') {
          const tryFindItemWithValue = items.find(
            ({ value: valueItem }) => valueItem === value
          )
          if (!tryFindItemWithValue) return acc
          return [...acc, tryFindItemWithValue]
        }

        if (type === 'multiple') {
          if (!value) return acc
          const indexFormatted = parseInt(key.split('|-|')[1])
          return [...acc, items[indexFormatted]]
        }

        return acc
      },
      []
    )
    return decode
    // return decode;
  }
}

export declare namespace SearchToolsType {
  type Props = {
    className?: string
    handleEvent?: {
      search?: (value: string) => void
      clear?: () => void
    }
    config?: {
      actions?: SearchToolsType.Config.Action[]
    }
  }

  namespace Config {
    type Action = (
      | SearchToolsType.Static.ActionSingle
      | SearchToolsType.Static.ActionMultiple
    ) & {
      key: string
      title: string
      subtitle?: string
      items: SearchToolsType.Static.ActionItem[]
    }
  }

  namespace Static {
    type Type = 'single' | 'multiple'

    type ActionItem = {
      value: string
      label: string
    }

    type ActionSingle = {
      type: Extract<SearchToolsType.Static.Type, 'single'>
      defaultSelected: SearchToolsType.Static.ActionItem['value']
      input: (value: string) => void
    }

    type ActionMultiple = {
      type: Extract<SearchToolsType.Static.Type, 'multiple'>
      defaultSelected: Array<SearchToolsType.Static.ActionItem['value']>
      input: (value: string[]) => void
    }

    type ActionState = Array<
      SearchToolsType.Config.Action & {
        currentSelectForm: Record<string, boolean> | {}
        currentSelectText: SearchToolsType.Static.ActionItem[]
      }
    >
  }
}

export const SearchTools = ({
  className = '',
  config: { actions = [] } = {},
  handleEvent: { search, clear } = {}
}: SearchToolsType.Props) => {
  const [actionsState, setActionsState] =
    useState<SearchToolsType.Static.ActionState>([])

  const [refresh, setRefresh] = useState<string>('')
  const { isDesktop } = useDevice()
  const { t } = useTranslation()

  useEffect(() => {
    setActionsState(
      actions.map(({ key, defaultSelected, items, type, ...rest }) => {
        const defaultSelectedFormatted = Array.isArray(defaultSelected)
          ? defaultSelected
          : [defaultSelected]

        const currentSelectText = defaultSelectedFormatted.reduce(
          (acc: SearchToolsType.Static.ActionItem[], value) => {
            const tryFindActionItem = items.find(
              ({ value: valueItem }) => valueItem === value
            ) as SearchToolsType.Static.ActionItem
            return [...acc, tryFindActionItem]
          },
          []
        )

        return {
          ...rest,
          key,
          type,
          items,
          defaultSelected,
          currentSelectForm: Static.encodeForm({
            key,
            type,
            items,
            currentSelectText
          }),
          currentSelectText
        } as any
      })
    )
  }, [])

  const actionsNode = useMemo(() => {
    const isAvailable = actionsState && Array.isArray(actionsState)
    if (!isAvailable) return <></>

    return actionsState.map(
      (
        {
          key,
          title,
          subtitle,
          type,
          items,
          currentSelectForm,
          currentSelectText,
          input
        },
        index
      ) => {
        const nodeForm = Static.buildFormNode({
          key,
          type,
          items,
          title,
          subtitle,
          currentSelectForm,
          oldActionState: actionsState,
          newActionState: (value) => {
            setActionsState(value)
            setRefresh(() => uuidv4())

            const tryFindAction = value.find(
              ({ key: keyAction }) => keyAction === key
            )

            if (type === 'multiple')
              tryFindAction &&
                input?.(
                  tryFindAction?.currentSelectText.map(({ value }) => value)
                )
            if (type === 'single')
              tryFindAction &&
                input?.(tryFindAction?.currentSelectText?.[0].value)
          }
        })

        const nodeTrigger = Static.buildTriggerNode({
          type,
          title,
          currentSelectText
        })

        return (
          <div key={`action-select-${index}`}>
            {isDesktop ? (
              <Popover data={nodeForm} config={{ placement: 'bottom' }}>
                {nodeTrigger}
              </Popover>
            ) : (
              <Drawer
                config={{ placement: 'bottom', size: 'small' }}
                data={nodeForm}
              >
                {nodeTrigger}
              </Drawer>
            )}
          </div>
        )
      }
    )
  }, [actionsState, refresh])

  return (
    <div className={`search-tools ${className}`}>
      <Search
        handleEvent={{
          search: (value) => {
            search?.(value)
          },
          clear: () => {
            clear?.()
          }
        }}
        className="search-tools__input"
        config={{ width: 'full' }}
      />
      <div className="search-tools__more">{actionsNode}</div>
    </div>
  )
}
