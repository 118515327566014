import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { DateTime } from 'luxon';
import { ResumeStats } from '../../../components/resume-stats/resume-stats.component';
import {
  knownColorWithAlarmsSeverity,
  resumeAlarmsStatsSortFn,
} from '../../../static/resumeAlarmsStats';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';

const iconsAlarms = {
  collect: 'faBoxSolid',
  energy: 'faBoltSolid',
  modem: 'faSignalSolid',
  printer: 'faPrintSolid',
};

const textAlarms = {
  'out-of-order': 'HS',
  degraded: 'Degraded',
  'action-requested': 'Intervention',
  info: 'Info',
};

export const search = (context, data) => {
  return {
    list: data.list.reduce(
      (
        acc,
        { _id, effect, label, type, logDate, terminalId, reference },
        index,
      ) => {
        return [
          ...acc,
          {
            _id: _id,
            items: {
              present: (
                <Icon config={{ type: iconsAlarms[type], size: 'list' }}></Icon>
              ),
              identity: (
                <Blurb
                  data={{
                    title: label,
                    description: `${reference.unique}${
                      reference?.custom ? ` ⋅ ${reference?.custom}` : ''
                    }`,
                    badge: [
                      {
                        text: textAlarms[effect],
                        color: knownColorWithAlarmsSeverity(effect),
                      },
                    ],
                  }}
                ></Blurb>
              ),
              time: (
                <Col>
                  <Write
                    config={{ mode: 'label', wrap: false }}
                    data={{
                      item: DateTime.fromISO(logDate).toRelative({
                        locale: 'en',
                      }),
                    }}
                  ></Write>
                </Col>
              ),
            },
          },
        ];
      },
      [],
    ),
    paging: data.paging,
  };
};

export const resume = (context, data) => {
  return {
    list: data.resume
      .sort((a, b) => resumeAlarmsStatsSortFn(a.effect, b.effect))
      .reduce(
        (acc, { count, effect, label }, index) => [
          ...acc,
          {
            _id: `resume-item-${index}`,

            items: {
              present: (
                <ResumeStats
                  data={count}
                  config={{ severity: effect }}
                ></ResumeStats>
              ),
              identity: <Blurb data={{ title: label }}></Blurb>,
            },
          },
        ],
        [],
      ),
    paging: { count: 1, current: 1, limit: 1 },
  };
};
