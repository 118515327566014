import { assign, createMachine, interpret } from 'xstate';
import { gql } from '@apollo/client';
import { requestGQL } from '@gimlite/watermelon/functions/request.function';

export const resumeAlarmsAll = gql`
  query resumeAlarmsAll($zoneId: String) {
    resumeAlarmsAll(zoneId: $zoneId) {
      resume {
        modem
        collect
        printer
        energy
      }
    }
  }
`;

const initialContext = {
  ups: {},
};

const appMachine = createMachine(
  {
    id: 'app',
    context: initialContext,
    initial: 'idle',
    predictableActionArguments: true,
    states: {
      idle: {
        on: {
          RESUME_ALARMS_ALL: {
            target: 'resumeAlarmsAll',
          },
          SET_UPS_CONFIG: {
            actions: assign({
              ups: (_, { payload }) => payload,
            }),
          },
        },
      },
      resumeAlarmsAll: {
        invoke: {
          id: 'resumeAlarmsAll',
          src: 'resumeAlarmsAll',
          onDone: {
            target: 'idle',
            actions: assign({
              resumeAlarmsAll: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
  },
  {
    services: {
      resumeAlarmsAll: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: resumeAlarmsAll,
        }),
    },
    guards: {
      ready: () => true,
    },
  },
);

export const appService = interpret(appMachine).start();
