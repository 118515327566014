import { RouterType, dispatch } from '@gimlite/router';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import {
  Maps,
  MapsType,
} from '@gimlite/watermelon/components/maps/maps.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { PagingInType } from '@gimlite/watermelon/components/pagination/pagination.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MachineDetails } from '../../../components/machine-details/machine-details';
import { SearchTools } from '../../../components/search-tools/search-tools.component';
import { zoneDetailsService } from '../../../components/select-zone/select-zone.machine';
import { Terminal } from '../terminals.machine';

export const TerminalsPage = ({ machine }: RouterType.Legacy) => {
  const [selectedId, setSelectedId] = useState<MapsType.Data['selected']>([]);
  const { isDesktop } = useDevice();
  const [paging, setPaging] = useState({ page: 1, limit: 40 });
  const [filter, setFilter] = useState<string[]>([]);
  const [sort, setSort] = useState<string>('mostSeverity');
  const [search, setSearch] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const searchTerminal = useSelector(
    machine,
    ({ context }: any) => context.searchTerminal,
  ) as { list: Terminal[] | undefined; paging: PagingInType };

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  const markers = useMemo(() => {
    if (!searchTerminal?.list) return null;
    return searchTerminal?.list.map(
      ({ _id, coordinates, reference: { unique, custom } }) => {
        return {
          id: _id,
          location: {
            lat: coordinates[0],
            lng: coordinates[1],
          },
          label: `${unique} ⋅ ${custom}`,
          markerStyleId: 'default',
        };
      },
    );
  }, [searchTerminal]);

  const redirectToMapExtern = useCallback((terminalId: string) => {
    window.location.href =
      'https://www.google.com/maps/place/Chem.+du+Pont-du-Centenaire+109,+1228+Plan-les-Ouates/@46.1708826,6.105688,17z/data=!3m1!4b1!4m6!3m5!1s0x478c7b6d81190043:0xc3104719cca31017!8m2!3d46.1708826!4d6.1082683!16s%2Fg%2F11c252s962?entry=ttu';
  }, []);

  useEffect(() => {
    if (selectedId && selectedId.length > 0) {
      setIsOpen(() => true);
    }
  }, [selectedId]);

  useEffect(() => {
    dispatch('SEARCH_TERMINAL', { ...paging, order: sort, search, zoneId });
  }, [paging, filter, sort, search, zoneId]);

  return (
    <Page>
      <Zone
        config={{
          gap: {
            x: 2,
          },
          zones: [!isDesktop ? ['list'] : ['list', 'map']],
          rows: ['1fr'],
          columns: !isDesktop
            ? ['1fr']
            : ['minmax(500px, min-content)', '2.5fr'],
        }}
      >
        <Zone.Area config={{ area: 'list' }}>
          <Widget.Group
            config={{
              title: searchTerminal?.paging?.count
                ? `List of machines (${searchTerminal?.paging?.count})`
                : 'List of machines',
              subtitle: 'Find all the machines in the current sector',
              responsive: {
                padding: false,
              },
            }}
          >
            <Zone
              config={{
                gap: {
                  y: 2,
                },
                zones: [['search'], ['terminals']],
                rows: ['min-content', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'search' }}>
                <Widget
                  config={{
                    responsive: {
                      padding: false,
                    },
                  }}
                >
                  <SearchTools
                    handleEvent={{
                      search: (value) => {
                        setSearch(value);
                      },
                    }}
                    config={{
                      actions: [
                        {
                          key: 'sort',
                          title: 'Sort',
                          type: 'single',
                          items: [
                            { value: 'name', label: 'Name' },
                            {
                              value: 'mostSeverity',
                              label: 'The most critical',
                            },
                            {
                              value: 'leastSeverity',
                              label: 'The least critical',
                            },
                          ],
                          defaultSelected: 'name',
                          input: (value) => {
                            setSort(value);
                          },
                        },
                        {
                          key: 'filtered',
                          title: 'Filter',
                          type: 'multiple',
                          items: [
                            { value: 'out-of-order', label: 'HS' },
                            {
                              value: 'degraded',
                              label: 'Degraded',
                            },
                            {
                              value: 'action-requested',
                              label: 'Intervention request',
                            },
                            { value: 'info', label: 'Information' },
                          ],
                          defaultSelected: [],
                          input: (value) => {
                            setFilter(value);
                          },
                        },
                      ],
                    }}
                  />
                </Widget>
              </Zone.Area>

              <Zone.Area config={{ area: 'terminals' }}>
                <Widget
                  config={{
                    responsive: {
                      padding: false,
                    },
                  }}
                >
                  {searchTerminal?.list ? (
                    <ListFlatInfo<any>
                      data={{
                        list: searchTerminal.list.map(
                          ({
                            _id,
                            address: { streetName, streetNumber },
                            reference: { unique, custom },
                          }) => ({
                            _id: _id,
                            items: {
                              present: (
                                <Icon
                                  config={{ type: 'faPlugSolid', size: 'list' }}
                                />
                              ),
                              identity: (
                                <Blurb
                                  data={{
                                    title: `${unique} ⋅ ${custom}`,
                                    description: `${streetNumber} ${streetName}`,
                                  }}
                                ></Blurb>
                              ),
                              itinerary: (
                                <Write
                                  handleEvent={{
                                    click: (e) => {
                                      e.stopPropagation();
                                      redirectToMapExtern(_id);
                                    },
                                  }}
                                  data={{ item: 'Itinerary' }}
                                  config={{
                                    mode: 'value-small',
                                    color: 'primary',
                                  }}
                                ></Write>
                              ),
                            },
                          }),
                        ),
                        paging: searchTerminal.paging,
                      }}
                      config={{
                        columns: [
                          { key: 'present', size: 'min-content' },
                          { key: 'identity', size: '1fr' },
                          { key: 'itinerary', size: 'min-content' },
                        ],
                        pagination: 'scroll',
                      }}
                      handleEvent={{
                        click: (id) => {
                          setSelectedId([id]);
                        },
                        paging: (page) => {
                          setPaging(page);
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Widget>
              </Zone.Area>
            </Zone>
          </Widget.Group>
        </Zone.Area>

        {isDesktop && (
          <Zone.Area config={{ area: 'map' }}>
            <Widget.Group
              config={{
                title: searchTerminal?.paging?.count
                  ? `Maps of machines (${searchTerminal?.paging?.count})`
                  : 'Maps of machines',
                subtitle: 'Find all the machines in the current sector',
              }}
            >
              {markers && (
                <Maps
                  config={{
                    selectionScaleFactor: 2,
                  }}
                  data={{
                    markers: markers as any,
                    selected: selectedId,
                  }}
                  handleEvent={{
                    select: (markerId) => setSelectedId([markerId]),
                    unselect: () => setSelectedId([]),
                  }}
                />
              )}
            </Widget.Group>
          </Zone.Area>
        )}
      </Zone>

      <MachineDetails
        handleEvent={{
          close: () => {
            setIsOpen(() => false);
            setSelectedId([]);
          },
        }}
        config={{ isOpen: isOpen }}
        data={{
          terminalId: selectedId?.[0] ? `${selectedId?.[0]}` : undefined,
        }}
      ></MachineDetails>
    </Page>
  );
};
