import { RouterType, dispatch } from '@gimlite/router';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { SearchTools } from '../../../components/search-tools/search-tools.component';
import { zoneDetailsService } from '../../../components/select-zone/select-zone.machine';
import { TransactionsDetails } from '../../../components/transactions-details/transactions-details';

export const TransactionsView = ({ machine }: RouterType.Legacy) => {
  const searchTransactions = useSelector(
    machine,
    ({ context }: any) => context.search,
  );
  const resumeTransactions = useSelector(
    machine,
    ({ context }: any) => context.resume,
  );

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  const [paging, setPaging] = useState({ page: 1, limit: 40 });
  const [filter, setFilter] = useState<string[]>([]);
  const [sort, setSort] = useState<string>('mostSeverity');
  const [search, setSearch] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string | null>(null);

  const { isDesktop } = useDevice();

  useEffect(() => {
    dispatch('SEARCH_TRANSACTIONS', {
      payload: {
        ...paging,
        status: filter,
        order: sort,
        search,
        zoneId,
      },
    });
  }, [paging, filter, sort, search, zoneId]);

  useEffect(() => {
    if (searchTransactions) {
      dispatch('RESUME_TRANSACTIONS', {
        payload: {
          zoneId,
        },
      });
    }
  }, [searchTransactions, zoneId]);

  const resumeNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: 'Detailed report',
          subtitle: 'Criticality level statistics',
          responsive: {
            padding: false,
          },
        }}
      >
        <ListFlatInfo<any>
          data={resumeTransactions}
          config={{
            columns: [
              { key: 'present', size: 'min-content' },
              { key: 'identity', size: '1fr' },
            ],
            yMax: isDesktop ? 'initial' : 'min-content',
            pagination: 'none',
          }}
        ></ListFlatInfo>
      </Widget>
    );
  }, [isDesktop, resumeTransactions]);

  const listNode = useMemo(() => {
    return (
      <Widget.Group
        config={{
          title: searchTransactions?.paging?.count
            ? `Current event (${searchTransactions?.paging?.count})`
            : 'Current event',
          subtitle: 'Lorem Ipsum is simply dummy text',
          responsive: {
            padding: false,
          },
        }}
      >
        <Zone
          config={{
            zones: [['search'], ['details']],
            columns: ['1fr'],
            rows: ['min-content', '1fr'],
            gap: {
              y: 1,
            },
          }}
        >
          <Zone.Area config={{ area: 'search' }}>
            <Widget
              config={{
                responsive: {
                  padding: false,
                },
              }}
            >
              <SearchTools
                handleEvent={{
                  search: (value) => {
                    setSearch(value);
                  },
                }}
                config={{
                  actions: [
                    {
                      key: 'sort',
                      title: 'Sort',
                      type: 'single',
                      items: [
                        { value: 'name', label: 'Name' },
                        {
                          value: 'mostSeverity',
                          label: 'The most critical',
                        },
                        {
                          value: 'leastSeverity',
                          label: 'The least critical',
                        },
                      ],
                      defaultSelected: sort,
                      input: (value) => {
                        setSort(value);
                      },
                    },
                    {
                      key: 'filtered',
                      title: 'Filter',
                      type: 'multiple',
                      items: [{ value: 'failure', label: 'Echec' }],
                      defaultSelected: filter,
                      input: (value) => {
                        setFilter(value);
                      },
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'details' }}>
            <Widget
              config={{
                responsive: {
                  padding: false,
                },
              }}
            >
              <ListFlatInfo<any>
                handleEvent={{
                  paging: (paging) => {
                    setPaging(paging);
                  },
                  click: (value) => {
                    setTransactionId(value);
                  },
                }}
                config={{
                  columns: [
                    { key: 'present', size: 'min-content' },
                    { key: 'identity', size: '1fr' },
                    { key: 'time', size: 'min-content' },
                  ],
                  pagination: 'scroll',
                }}
                data={searchTransactions}
              ></ListFlatInfo>
            </Widget>
          </Zone.Area>
        </Zone>
      </Widget.Group>
    );
  }, [isDesktop, searchTransactions]);

  return (
    <Page>
      <TransactionsDetails data={{ transactionId: transactionId }} />
      {isDesktop ? (
        <Zone
          config={{
            gap: {
              x: 2,
            },
            zones: [['bilan', 'list']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'bilan' }}>{resumeNode}</Zone.Area>
          <Zone.Area config={{ area: 'list' }}>{listNode}</Zone.Area>
        </Zone>
      ) : (
        <Col>
          {resumeNode}
          <Space config={{ count: 5, way: 'vertical' }}></Space>
          {listNode}
        </Col>
      )}
    </Page>
  );
};
