import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Drawer } from '@gimlite/watermelon/components/drawer/drawer.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { MachineDetails } from '../machine-details/machine-details';
import { transactionsDetailsService } from './transactions-details.machine';

export declare namespace TransactionsDetailsType {
  type Props = {
    className?: string;
    data: {
      transactionId: string | null;
    };
  };
}

export const TransactionsDetails = ({
  data: { transactionId },
  className = '',
}: TransactionsDetailsType.Props) => {
  const { isDesktop } = useDevice();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const readTransaction = useSelector(
    transactionsDetailsService,
    ({ context }: any) => context.readTransaction,
  );

  const infos = useMemo(() => {
    if (!readTransaction) return null;

    const { transaction } = readTransaction;
    const infosBuild: any = [
      {
        label: 'Category',
        value: 'Transactions',
      },
      {
        label: 'Status',
        value: transaction?.status,
      },
      {
        label: 'Description',
        value: transaction?.description,
      },
      {
        label: 'Plaque d’immatriculation',
        value: transaction?.lpn,
      },
      {
        label: 'Machine reference',
        value: (
          <MachineDetails
            data={{
              terminalId: transaction?.terminalId,
            }}
          >
            <button style={{ backgroundColor: 'transparent', outline: 'none' }}>
              <Row>
                <Write
                  data={{
                    item: transaction?.reference?.unique,
                  }}
                  config={{ mode: 'label', color: 'primary' }}
                ></Write>

                {transaction?.reference?.custom && (
                  <Write
                    data={{
                      item: ` ⋅ ${transaction.reference.custom}`,
                    }}
                    config={{ mode: 'label', color: 'primary' }}
                  ></Write>
                )}
              </Row>
            </button>
          </MachineDetails>
        ),
      },
      {
        label: 'Last call',
        value: transaction?.logDate,
      },
    ];

    return (
      <>
        <Widget
          config={{
            title: 'Event information',
            shadow: false,
            responsive: {
              padding: false,
            },
          }}
        >
          <Description
            data={infosBuild}
            config={{
              mode: 'line',
            }}
          />
        </Widget>
      </>
    );
  }, [readTransaction]);

  useEffect(() => {
    if (transactionId) {
      transactionsDetailsService.send('READ_TRANSACTION', {
        payload: {
          transactionId,
        },
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (infos && !isOpen) {
      setIsOpen(() => true);
    }
  }, [infos]);

  useEffect(() => {
    return () => {
      setIsOpen(() => false);
      transactionsDetailsService.send('RESET', {});
    };
  }, []);

  return transactionId ? (
    <Drawer
      handleEvent={{
        close: () => {
          setIsOpen(() => false);
        },
      }}
      className={className}
      data={infos}
      config={{
        isOpen,
        size: isDesktop ? 'medium' : 'xlarge',
        placement: isDesktop ? 'right' : 'bottom',
      }}
    />
  ) : null;
};
