import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Drawer } from '@gimlite/watermelon/components/drawer/drawer.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { MachineDetails } from '../machine-details/machine-details';
import { alarmsDetailsService } from './alarms-details.machine';

export declare namespace AlarmsDetailsType {
  type Props = {
    className?: string;
    data: {
      alarmId: string | null;
    };
  };
}

export const AlarmsDetails = ({
  data: { alarmId },
  className = '',
}: AlarmsDetailsType.Props) => {
  const { isDesktop } = useDevice();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const readAlarm = useSelector(
    alarmsDetailsService,
    ({ context }: any) => context.readAlarm,
  );

  const infos = useMemo(() => {
    if (!readAlarm) return null;

    const { alarm } = readAlarm;
    const infosBuild: any = [
      {
        label: 'Category',
        value: 'Alarms',
      },
      {
        label: 'Subcategory',
        value: alarm?.type,
      },
      {
        label: 'Status',
        value: alarm?.effect,
      },
      {
        label: 'Description',
        value: alarm?.label,
      },
      {
        label: 'Machine reference',
        value: (
          <MachineDetails
            data={{
              terminalId: alarm?.terminalId,
            }}
          >
            <button style={{ backgroundColor: 'transparent', outline: 'none' }}>
              <Row>
                <Write
                  data={{
                    item: alarm?.reference?.unique,
                  }}
                  config={{ mode: 'label', color: 'primary' }}
                ></Write>

                {alarm?.reference?.custom && (
                  <Write
                    data={{
                      item: ` ⋅ ${alarm.reference.custom}`,
                    }}
                    config={{ mode: 'label', color: 'primary' }}
                  ></Write>
                )}
              </Row>
            </button>
          </MachineDetails>
        ),
      },
      {
        label: 'Last call',
        value: alarm?.logDate,
      },
    ];

    return (
      <>
        <Widget
          config={{
            title: 'Event information',
            shadow: false,
            responsive: {
              padding: false,
            },
          }}
        >
          <Description
            data={infosBuild}
            config={{
              mode: 'line',
            }}
          />
        </Widget>
      </>
    );
  }, [readAlarm]);

  useEffect(() => {
    if (alarmId) {
      alarmsDetailsService.send('READ_ALARM', {
        payload: {
          alarmId,
        },
      });
    }
  }, [alarmId]);

  useEffect(() => {
    if (infos && !isOpen) {
      setIsOpen(() => true);
    }
  }, [infos]);

  useEffect(() => {
    return () => {
      setIsOpen(() => false);
      alarmsDetailsService.send('RESET', {});
    };
  }, []);

  return alarmId ? (
    <Drawer
      handleEvent={{
        close: () => {
          setIsOpen(() => false);
        },
      }}
      className={className}
      data={infos}
      config={{
        isOpen: isOpen,
        size: isDesktop ? 'medium' : 'large',
        placement: isDesktop ? 'right' : 'bottom',
      }}
    />
  ) : null;
};
