import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  selectedZone: 'global',
};

const zoneDetailsMachine = createMachine(
  {
    id: 'zones',
    initial: 'idle',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      idle: {
        on: {
          REGISTER_ZONE: {
            actions: assign({
              selectedZone: (context, { payload }) => payload.key,
            }),
          },
          RESET: {
            actions: assign({
              readZone: () => undefined,
            }),
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('alarms KILLED');
      },
      logWakeup: () => {
        console.log(`alarms WAKEUP`);
      },
    },
    guards: {
      ready: () => true,
    },
  },
);

export const zoneDetailsService = interpret(zoneDetailsMachine).start();
