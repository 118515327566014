import { RouterType, dispatch } from '@gimlite/router';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { AlarmsDetails } from '../../../components/alarm-details/alarm-details';
import { SearchTools } from '../../../components/search-tools/search-tools.component';
import { zoneDetailsService } from '../../../components/select-zone/select-zone.machine';

export const AlarmsView = ({
  machine,
  type,
}: RouterType.Legacy & { type: string }) => {
  const searchAlarms = useSelector(
    machine,
    ({ context }: any) => context.search,
  );
  const resumeAlarms = useSelector(
    machine,
    ({ context }: any) => context.resume,
  );

  const [paging, setPaging] = useState({ page: 1, limit: 40 });
  const [filter, setFilter] = useState<string[]>([]);
  const [sort, setSort] = useState<string>('mostSeverity');
  const [search, setSearch] = useState<string | null>(null);
  const [alarmId, setAlarmId] = useState<string | null>(null);

  const { isDesktop } = useDevice();

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  useEffect(() => {
    dispatch('SEARCH_ALARMS', {
      payload: {
        type,
        ...paging,
        effects: filter,
        order: sort,
        search,
        zoneId,
      },
    });
  }, [paging, filter, sort, search, zoneId]);

  useEffect(() => {
    if (searchAlarms) {
      dispatch('RESUME_ALARMS', {
        payload: {
          type,
          zoneId,
        },
      });
    }
  }, [searchAlarms]);

  const resumeNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: 'Detailed report',
          subtitle: 'Criticality level statistics',
          responsive: {
            padding: false,
          },
        }}
      >
        <ListFlatInfo<any>
          data={resumeAlarms}
          config={{
            yMax: isDesktop ? 'initial' : 'min-content',
            pagination: 'none',
            columns: [
              { key: 'present', size: 'min-content' },
              { key: 'identity', size: '1fr' },
            ],
          }}
        ></ListFlatInfo>
      </Widget>
    );
  }, [isDesktop, resumeAlarms]);

  const listNode = useMemo(() => {
    return (
      <Widget.Group
        config={{
          title: searchAlarms?.paging?.count
            ? `Current event (${searchAlarms?.paging?.count})`
            : 'Current event',
          subtitle: 'Lorem Ipsum is simply dummy text',
          responsive: {
            padding: false,
          },
        }}
      >
        <Zone
          config={{
            zones: [['search'], ['details']],
            columns: ['1fr'],
            rows: ['min-content', '1fr'],
            gap: {
              y: 1,
            },
          }}
        >
          <Zone.Area config={{ area: 'search' }}>
            <Widget
              config={{
                responsive: {
                  padding: false,
                },
              }}
            >
              <SearchTools
                handleEvent={{
                  search: (value) => {
                    setSearch(value);
                  },
                }}
                config={{
                  actions: [
                    {
                      key: 'sort',
                      title: 'Sort',
                      type: 'single',
                      items: [
                        { value: 'name', label: 'Name' },
                        {
                          value: 'mostSeverity',
                          label: 'The most critical',
                        },
                        {
                          value: 'leastSeverity',
                          label: 'The least critical',
                        },
                      ],
                      defaultSelected: sort,
                      input: (value) => {
                        setSort(value);
                      },
                    },
                    {
                      key: 'filtered',
                      title: 'Filter',
                      type: 'multiple',
                      items: [
                        { value: 'out-of-order', label: 'HS' },
                        {
                          value: 'degraded',
                          label: 'Degraded',
                        },
                        {
                          value: 'action-requested',
                          label: 'Intervention request',
                        },
                        { value: 'info', label: 'Information' },
                      ],
                      defaultSelected: filter,
                      input: (value) => {
                        setFilter(value);
                      },
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'details' }}>
            <Widget
              config={{
                responsive: {
                  padding: false,
                },
              }}
            >
              <ListFlatInfo<any>
                handleEvent={{
                  paging: (paging) => {
                    setPaging(paging);
                  },
                  click: (value) => setAlarmId(value),
                }}
                config={{
                  pagination: 'scroll',
                  columns: [
                    { key: 'present', size: 'min-content' },
                    { key: 'identity', size: '1fr' },
                    { key: 'time', size: 'min-content' },
                  ],
                }}
                data={searchAlarms}
              ></ListFlatInfo>
            </Widget>
          </Zone.Area>
        </Zone>
      </Widget.Group>
    );
  }, [isDesktop, searchAlarms]);

  return (
    <Page>
      <AlarmsDetails data={{ alarmId }} />
      {isDesktop ? (
        <Zone
          config={{
            gap: {
              x: 2,
            },
            zones: [['bilan', 'list']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'bilan' }}>{resumeNode}</Zone.Area>
          <Zone.Area config={{ area: 'list' }}>{listNode}</Zone.Area>
        </Zone>
      ) : (
        <Col>
          {resumeNode}
          <Space config={{ count: 5, way: 'vertical' }}></Space>
          {listNode}
        </Col>
      )}
    </Page>
  );
};
