import { gql } from '@apollo/client';

export const readTransaction = gql`
  query readTransaction($transactionId: String!) {
    readTransaction(transactionId: $transactionId) {
      transaction {
        _id
        lpn
        terminalId
        description
        status
        logDate
        reference {
          unique
          custom
        }
      }
    }
  }
`;
