import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { ResumeHealth } from '../components/resume-health/resume-health.component';
import type { ResumeTransactionsSeverity } from '../static/resumeTransactionsStats';

export const searchSystemsData: any[] = [
  { status: 'success', label: 'Health server' },
  { status: 'success', label: 'Ticket server' },
  { status: 'failure', label: 'FPS server' },
  { status: 'success', label: 'Legal server' },
  { status: 'success', label: 'Other' },
];

export const searchSystemsDataNode: any[] = searchSystemsData.map(
  ({ status, label }, index) => ({
    _id: `resume-item-${index}`,
    items: {
      present: (
        <ResumeHealth
          config={{ severity: status as ResumeTransactionsSeverity }}
        ></ResumeHealth>
      ),
      identity: <Blurb data={{ title: label }}></Blurb>,
    },
  }),
);

export const peripheralsDataNode: any = {
  list: [
    {
      _id: '1',
      items: {
        present: <Icon config={{ type: 'faCloudArrowDownSolid' }} />,
        identity: (
          <Blurb
            data={{
              title: 'modem',
              description: 'sw:0.0.5 - hw:A - boot:9.9.9',
            }}
          ></Blurb>
        ),
      },
    },
    {
      _id: '2',
      items: {
        present: <Icon config={{ type: 'faCloudArrowDownSolid' }} />,
        identity: (
          <Blurb
            data={{
              title: 'touchscreen',
              description: 'sw:8.2.8 - hw:A - boot:7.3.2',
            }}
          ></Blurb>
        ),
      },
    },
    {
      _id: '3',
      items: {
        present: <Icon config={{ type: 'faCloudArrowDownSolid' }} />,
        identity: (
          <Blurb
            data={{
              title: 'doorlocker',
              description: 'sw:4.9.5 - hw:B - boot:3.2.6',
            }}
          ></Blurb>
        ),
      },
    },
    {
      _id: '4',
      items: {
        present: <Icon config={{ type: 'faCloudArrowDownSolid' }} />,
        identity: (
          <Blurb
            data={{
              title: 'safelocker',
              description: 'sw:7.6.7 - hw:A - boot:1.2.8',
            }}
          ></Blurb>
        ),
      },
    },
    {
      _id: '5',
      items: {
        present: <Icon config={{ type: 'faCloudArrowDownSolid' }} />,
        identity: (
          <Blurb
            data={{
              title: 'board',
              description: 'sw:5.8.2 - hw:A - boot:6.6.9',
            }}
          ></Blurb>
        ),
      },
    },
  ],
  paging: {
    current: 1,
    limit: 10,
    count: 5,
  },
};
