import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { resumeTransactions } from './gql/resumeTransactions.gql';
import { searchTransactions } from './gql/searchTransactions.gql';
import { resume, search } from './projection/transactions.render';

const initialContext = {
  search: undefined,
  resume: undefined,
};

const transactionsMachine = createMachine(
  {
    id: 'transactions',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: 'logWakeup',
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            actions: 'logKill',
            target: 'off',
          },
          SEARCH_TRANSACTIONS: {
            target: 'search',
          },
          RESUME_TRANSACTIONS: {
            target: 'resume',
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              search: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      resume: {
        invoke: {
          id: 'resume',
          src: 'resume',
          onDone: {
            target: 'idle',
            actions: assign({
              resume: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('Transactions KILLED');
      },
      logWakeup: () => {
        console.log(`Transactions WAKEUP`);
      },
    },
    services: {
      search: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: searchTransactions,
          render: (res) => search(context, res),
        }),
      resume: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: resumeTransactions,
          render: (res) => resume(context, res),
        }),
    },

    guards: {
      ready: () => true,
    },
  },
);

export const transactionsService = interpret(transactionsMachine).start();
