import { RouterType, dispatch } from '@gimlite/router';
import {
  Maps,
  MapsType,
} from '@gimlite/watermelon/components/maps/maps.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { PagingInType } from '@gimlite/watermelon/components/pagination/pagination.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { MachineDetails } from '../../../components/machine-details/machine-details';
import { zoneDetailsService } from '../../../components/select-zone/select-zone.machine';
import { Terminal } from '../../terminals/terminals.machine';

export const MapsView = ({ machine }: RouterType.Legacy) => {
  const [selectedId, setSelectedId] = useState<MapsType.Data['selected']>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const searchTerminal = useSelector(
    machine,
    ({ context }: any) => context.searchTerminal,
  ) as { list: Terminal[] | undefined; paging: PagingInType };

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  const markers = useMemo(() => {
    if (!searchTerminal?.list) return null;
    return searchTerminal?.list.map(
      ({ _id, coordinates, reference: { unique, custom } }) => {
        return {
          id: _id,
          location: {
            lat: coordinates[0],
            lng: coordinates[1],
          },
          label: `${unique} ⋅ ${custom}`,
          markerStyleId: 'default',
        };
      },
    );
  }, [searchTerminal]);

  useEffect(() => {
    if (selectedId && selectedId.length > 0) {
      setIsOpen(() => true);
    }
  }, [selectedId]);

  useEffect(() => {
    dispatch('SEARCH_TERMINAL', { zoneId });
  }, [zoneId]);
  return (
    <Page>
      <Widget
        config={{
          title: 'Maps of machines',
          subtitle: 'Find all the machines in the current sector',
        }}
      >
        {markers && (
          <Maps
            config={{
              selectionScaleFactor: 2,
              yMax: '85dvh',
            }}
            data={{
              markers: markers as any,
              selected: selectedId,
            }}
            handleEvent={{
              select: (markerId) => setSelectedId([markerId]),
              unselect: () => setSelectedId([]),
            }}
          />
        )}
      </Widget>

      <MachineDetails
        handleEvent={{ close: () => setSelectedId([]) }}
        config={{ isOpen: isOpen }}
        data={{
          terminalId: selectedId?.[0] ? `${selectedId?.[0]}` : undefined,
        }}
      ></MachineDetails>
    </Page>
  );
};
