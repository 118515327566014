import { gql } from '@apollo/client';

export const resumeTransactions = gql`
  query resumeTransactions($zoneId: String) {
    resumeTransactions(zoneId: $zoneId) {
      resume {
        count
        status
        description
      }
    }
  }
`;
