import { gql } from '@apollo/client';

export const readAlarm = gql`
  query readAlarm($alarmId: String!) {
    readAlarm(alarmId: $alarmId) {
      alarm {
        _id
        effect
        label
        type
        logDate
        terminalId
        reference {
          unique
          custom
        }
      }
    }
  }
`;
