import { Color } from '@gimlite/watermelon/types/component.type'

export type ResumeTransactionsSeverity = 'failure' | 'success'

type ColorManager = Extract<Color, 'error'>

export const resumeStatsTransactionsSort: Array<ResumeTransactionsSeverity> = [
  'success',
  'failure'
]

export const resumeStatsTransactionsColor: any = {
  failure: 'error',
  success: 'success'
}

export const resumeTransactionsStatsSortFn = (
  a: ResumeTransactionsSeverity,
  b: ResumeTransactionsSeverity
) =>
  resumeStatsTransactionsSort.indexOf(b) -
  resumeStatsTransactionsSort.indexOf(a)

export const knownColorWithTransactionsSeverity = (
  level: ResumeTransactionsSeverity
): ColorManager => resumeStatsTransactionsColor[level]
