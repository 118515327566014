import { Paginated } from '@gimlite/watermelon'
import { IEMMachineBuilder } from '@gimlite/watermelon/machines/machine-factory'
import { searchTerminals } from './gql/searchTerminals.gql'

type TerminalStatistic = {
  label: string
  value: string
}

export type Terminal = {
  _id: string
  reference: {
    unique: string
    custom?: string
  }
  address: {
    streetNumber: number
    streetName: string
    city: string
  }
  statistics?: TerminalStatistic[]
  coordinates: [number, number]
}

export const terminalMachine = new IEMMachineBuilder('terminal')
  .withSearch<Paginated<Terminal>>(searchTerminals)
  .log()
  .run()
