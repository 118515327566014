import { Config } from '@gimlite/watermelon/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import config from './config';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Config
      debug={false}
      version={{
        env: config.ENV,
        front: config.VERSION_FRONT,
      }}
      isResponsive={false}
      server={{
        bff: config.BFF_HOST,
        ws: config.BFF_WS_HOST,
      }}
      authorization={config.BFF_TOKEN}
      theme={{
        color: {
          dark: '#5dbed2',
          light: '#0a2652',
        },
        defaultTheme: 'light',
      }}
      maps={config.GOOGLE_MAPS_API_KEY}
    >
      <CustomRouter />
    </Config>
  </React.StrictMode>,
);
