import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  search: undefined,
};

const systemsMachine = createMachine(
  {
    id: 'systems',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: 'logWakeup',
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            actions: 'logKill',
            target: 'off',
          },
          SEARCH_SYSTEMS: {
            target: 'search',
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              search: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('Systems KILLED');
      },
      logWakeup: () => {
        console.log(`Systems WAKEUP`);
      },
    },
    services: {
      search: (context, { payload: params }) => {
        return [];
      },
    },

    guards: {
      ready: () => true,
    },
  },
);

export const SystemsService = interpret(systemsMachine).start();
