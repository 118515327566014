import { gql } from '@apollo/client';

export const terminalsGql = gql`
  query sites(
    $page: Int
    $limit: Int
    $order: String
    $query: String
  ) {
    sites(
      page: $page
      limit: $limit
      order: $order
      query: $query
    ) {
      list {
        _id
        name
        coordinates
        address1
        address2
        monitoring {
          _id
          status
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`