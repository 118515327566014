import { gql } from '@apollo/client';

export const searchTransactions = gql`
  query searchTransactions(
    $page: Int!
    $limit: Int!
    $order: String
    $zoneId: String
  ) {
    searchTransactions(
      page: $page
      limit: $limit
      order: $order
      zoneId: $zoneId
    ) {
      list {
        _id
        lpn
        terminalId
        description
        status
        reference {
          unique
          custom
        }
        logDate
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
