import { assign, createMachine } from 'xstate';
import { interpret } from 'xstate';
import { resumeAlarms } from './gql/resumeAlarms.gql';
import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { resumeAlarms as resumeAlarmsRender } from './projection/alarms.render';
import { terminalsGql } from './gql/terminals.gql';
import { searchTerminals } from './gql/searchTerminals.gql';

const initialContext = {
  resume: undefined,
};

const homeMachine = createMachine(
  {
    id: 'home',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: 'logWakeup',
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            actions: 'logKill',
            target: 'off',
          },
          RESUME_ALARMS: {
            target: 'resumeAlarms',
          },
          SEARCH_TERMINALS: {
            target: 'searchTerminals',
          },
        },
      },
      resumeAlarms: {
        invoke: {
          id: 'resumeAlarms',
          src: 'resumeAlarms',
          onDone: {
            target: 'idle',
            actions: assign({
              resumeAlarms: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      searchTerminals: {
        invoke: {
          id: 'searchTerminals',
          src: 'searchTerminals',
          onDone: {
            target: 'idle',
            actions: assign({
              searchTerminals: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('home KILLED');
      },
      logWakeup: () => {
        // console.log(`home WAKEUP`);
      },
    },
    services: {
      resumeAlarms: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: resumeAlarms,
          render: (res) => resumeAlarmsRender(context, res),
        }),
      searchTerminals: (context, { payload: params }) =>
        requestGQL({
          params,
          gql: searchTerminals,
        }),
    },
    guards: {
      ready: () => true,
    },
  },
);

export const homeService = interpret(homeMachine).start();
