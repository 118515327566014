import { DateTime } from 'luxon';
import {
  knownColorWithTransactionsSeverity,
  resumeTransactionsStatsSortFn,
} from '../../../static/resumeTransactionsStats';
import { toCapitalizeCase } from '@gimlite/watermelon/functions/string.function';
import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { ResumeStats } from '../../../components/resume-stats/resume-stats.component';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';

export const search = (context, data) => {
  return {
    list: data.list.reduce(
      (acc, { _id, status, lpn, logDate, terminalId, reference }, index) => {
        return [
          ...acc,
          {
            _id: _id,
            items: {
              present: (
                <Icon config={{ type: 'faSignalSolid', size: 'list' }}></Icon>
              ),
              identity: (
                <Blurb
                  data={{
                    title: lpn,
                    description: `${reference.unique}${
                      reference?.custom ? ` ⋅ ${reference?.custom}` : ''
                    }`,
                    badge: [
                      {
                        text: toCapitalizeCase(status),
                        color: knownColorWithTransactionsSeverity(status),
                      },
                    ],
                  }}
                />
              ),
              time: (
                <Col>
                  <Write
                    config={{ mode: 'label', wrap: false }}
                    data={{
                      item: DateTime.fromISO(logDate).toRelative({
                        locale: 'en',
                      }),
                    }}
                  ></Write>
                </Col>
              ),
            },
          },
        ];
      },
      [],
    ),
    paging: data.paging,
  };
};

export const resume = (context, data) => {
  return {
    list: data.resume
      .sort((a, b) => resumeTransactionsStatsSortFn(a.status, b.status))
      .reduce(
        (acc, { count, status, description }, index) => [
          ...acc,
          {
            _id: `resume-item-${index}`,
            items: {
              present: (
                <ResumeStats
                  data={count}
                  config={{ severity: status }}
                ></ResumeStats>
              ),
              identity: <Blurb data={{ title: description }}></Blurb>,
            },
          },
        ],
        [],
      ),
    paging: { count: 1, current: 1, limit: 1 },
  };
};
