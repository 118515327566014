import { gql } from '@apollo/client';

export const searchTerminals = gql`
  query searchTerminals(
    $page: Int
    $limit: Int
    $order: String
    $zoneId: String
    $search: String
  ) {
    searchTerminals(
      page: $page
      limit: $limit
      order: $order
      zoneId: $zoneId
      search: $search
    ) {
      list {
        _id
        reference {
          unique
          custom
        }
        address {
          streetNumber
          streetName
          city
        }
        statistics {
          label
          value
        }
        coordinates
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
