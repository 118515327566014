import { dispatch, useNavigate } from '@gimlite/router';
import { useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { getCSS } from '@gimlite/watermelon/functions/css.function';
import { toCapitalizeCase } from '@gimlite/watermelon/functions/string.function';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { useCallback, useEffect } from 'react';
import { appService } from './app.machine';
import LogoIEMPrestoOne from './assets/logo/iem-prestone';
import { ResumeStats } from './components/resume-stats/resume-stats.component';
import { SelectZone } from './components/select-zone/select-zone.component';
import { zoneDetailsService } from './components/select-zone/select-zone.machine';
import { searchSystemsData } from './data/system';
import { resumeStatsAlarmsSort } from './static/resumeAlarmsStats';

class Static {
  static statsAllFormatted = (states: Record<string, number[]>) => {
    return Object.entries(states).reduce(
      (acc: number[], [key, array], index) => {
        if (index === 0) return array;
        const newAcc = acc;
        return newAcc.map((count, indexCurrent) => count + array[indexCurrent]);
      },
      [],
    );
  };
}

const MainUse = () => {
  const { AuthStore } = useRootStore();
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const me = AuthStore.me as any;

  const { isDesktop } = useDevice();

  const zoneId = useSelector(
    zoneDetailsService,
    ({ context }: { context: any }) => context.selectedZone,
  );

  const resumeAlarmsAll = useSelector(
    appService,
    ({ context }: { context: any }) => context.resumeAlarmsAll,
  ) as any;

  const alarmsState = useCallback((array: number[]) => {
    return (
      <Row>
        {array.map((number, index) => {
          return (
            <div style={{ margin: '0px -2px' }} key={index}>
              <ResumeStats
                data={number}
                config={{
                  severity: resumeStatsAlarmsSort[index],
                  size: 'xsmall',
                }}
              ></ResumeStats>
            </div>
          );
        })}
      </Row>
    );
  }, []);

  const systemsState = useCallback((success: number, failure: number) => {
    const formattedSystemsState = [
      { color: 'success', count: success },
      { color: 'error', count: failure },
    ];
    return (
      <Row>
        {formattedSystemsState.map(({ color, count }: any, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `2px solid ${getCSS(`--color-${color}`)}`,
                width: '2rem',
                height: '1.31rem',
                borderRadius: '5px',
                margin: '0px 1px',
              }}
            >
              <Icon
                config={{
                  type: color === 'success' ? 'faCheckSolid' : 'faXmarkSolid',
                  color: color,
                  size: 'xxxsmall',
                }}
              ></Icon>
              <span
                style={{
                  fontWeight: '600',
                  marginLeft: '1px',
                  fontSize: '0.82rem',
                  color: getCSS(`--color-${color}`),
                  lineHeight: 0,
                }}
              >
                {count}
              </span>
            </div>
          );
        })}
      </Row>
    );
  }, []);

  useEffect(() => {
    appService.send('RESUME_ALARMS_ALL', { payload: { zoneId } });
  }, [zoneId]);

  // Get account informations and dictionnary
  useEffect(() => {
    dispatch('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-presto-one-pms-global' },
    });

    AuthStore.initMe();
  }, []);

  useEffect(() => {
    if (me) {
      const { firstName, lastName, lang } = me;
      // Display avatar
      AuthStore.setNames({ firstName, lastName });

      // Select user language
      dispatch('SELECTED_LANG', { lang });
    }
  }, [me]);

  useEffect(() => {
    if (me) {
      const menuTop = [
        {
          key: `/`,
          label: 'Home',
          icon: 'faHomeSolid',
        },
        {
          key: `/terminals`,
          label: 'Machines',
          icon: 'faPlugSolid',
        },
        {
          key: `/systems`,
          label: 'System',
          icon: 'faServerSolid',
          extra: systemsState(
            searchSystemsData.filter(({ status }: any) => status === 'success')
              .length,
            searchSystemsData.filter(({ status }: any) => status === 'failure')
              .length,
          ),
        },
        {
          key: `/alarms/all`,
          label: 'Alarms',
          icon: 'faBellSolid',
          items: [
            {
              key: `/alarms/all`,
              label: 'All',
              icon: 'faAsteriskSolid',
              extra: resumeAlarmsAll?.resume ? (
                alarmsState(Static.statsAllFormatted(resumeAlarmsAll?.resume))
              ) : (
                <></>
              ),
            },
            {
              key: `/alarms/modem`,
              label: 'Modem',
              icon: 'faSignalSolid',
              extra: resumeAlarmsAll ? (
                alarmsState(resumeAlarmsAll?.resume.modem)
              ) : (
                <></>
              ),
            },
            {
              key: `/alarms/collect`,
              label: 'Collect',
              icon: 'faBoxSolid',
              extra: resumeAlarmsAll ? (
                alarmsState(resumeAlarmsAll?.resume.collect)
              ) : (
                <></>
              ),
            },
            {
              key: `/alarms/printer`,
              label: 'Printer',
              icon: 'faPrintSolid',
              extra: resumeAlarmsAll ? (
                alarmsState(resumeAlarmsAll?.resume.printer)
              ) : (
                <></>
              ),
            },
            {
              key: `/alarms/energy`,
              label: 'Energy',
              icon: 'faBoltSolid',
              extra: resumeAlarmsAll ? (
                alarmsState(resumeAlarmsAll?.resume.energy)
              ) : (
                <></>
              ),
            },
          ],
        },
        {
          key: `/transactions`,
          label: 'Transactions',
          icon: 'faCoinsSolid',
        },
      ];

      if (!isDesktop)
        menuTop.splice(1, 0, {
          key: `/maps`,
          label: 'Map',
          icon: 'faMapSolid',
        });

      const menu = [
        {
          key: 'top',
          position: 'top',
          title: 'Global',
          items: menuTop,
        },
        {
          key: 'bottom',
          position: 'bottom',
          title: '',
          items: [
            {
              key: 'logout',
              label: toCapitalizeCase(t('logout')),
              icon: 'faArrowRightFromBracketSolid',
            },
          ],
        },
      ];

      dispatch('REGISTER_MENU', {
        menu: menu,
      });
    }
  }, [me, lang, isDesktop, resumeAlarmsAll]);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          if (key === 'logout') {
            dispatch('LOGOUT', {});
            navigate('/');
          } else {
            navigate(key);
          }
        },
      }}
      config={{
        navigation: {
          logo: LogoIEMPrestoOne(),
          mode: 'drop',
        },
        //! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
        //******* START ************/
        header: {
          logo: IEM(),
          extra: <SelectZone />,
        },
        //******* STOP ************/
      }}
    />
  );
};

export default MainUse;
