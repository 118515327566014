import { Color } from '@gimlite/watermelon/types/component.type'

export type ResumeAlarmsSeverity =
  | 'info'
  | 'action-requested'
  | 'degraded'
  | 'out-of-order'

type ColorManager = Extract<Color, 'info' | 'yell' | 'warn' | 'error'>

export const resumeStatsAlarmsSort: Array<ResumeAlarmsSeverity> = [
  'info',
  'action-requested',
  'degraded',
  'out-of-order'
]

export const resumeStatsAlarmsColor: any = {
  info: 'info',
  'action-requested': 'yell',
  degraded: 'warn',
  'out-of-order': 'error'
}

export const resumeAlarmsStatsSortFn = (
  a: ResumeAlarmsSeverity,
  b: ResumeAlarmsSeverity
) => resumeStatsAlarmsSort.indexOf(b) - resumeStatsAlarmsSort.indexOf(a)

export const knownColorWithAlarmsSeverity = (
  level: ResumeAlarmsSeverity
): ColorManager => resumeStatsAlarmsColor[level]
