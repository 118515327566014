import { Col } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Drawer } from '@gimlite/watermelon/components/drawer/drawer.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Maps } from '@gimlite/watermelon/components/maps/maps.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { peripheralsDataNode } from '../../data/system';
import { SearchTools } from '../search-tools/search-tools.component';
import { terminalDetailsService } from './machine-details.machine';
import './machine-details.scss';

type TerminalStatistic = {
  label: string;
  value: string;
};

export type Terminal = {
  _id: string;
  reference: {
    unique: string;
    custom?: string;
  };
  address: {
    streetNumber: number;
    streetName: string;
    city: string;
  };
  statistics?: TerminalStatistic[];
  coordinates: [number, number];
};

export declare namespace MachineDetailsType {
  type Props = {
    children?: React.ReactNode;
    className?: string;
    handleEvent?: {
      close?: () => void;
    };
    data: {
      terminalId: string | undefined;
    };
    config?: {
      isOpen?: boolean;
    };
  };
}

export const MachineDetails = ({
  data: { terminalId },
  config: { isOpen } = {},
  handleEvent: { close } = {},
  className = '',
  children,
}: MachineDetailsType.Props) => {
  const [sortPeriph, setSortPeriph] = useState<string>('name');
  const [filterPeriph, setFilterPeriph] = useState<string[]>([]);
  const { isDesktop } = useDevice();
  const [searchPeriph, setSearchPeriph] = useState<string>('');

  const readTerminal = useSelector(
    terminalDetailsService,
    ({ context }: any) => context.readTerminal,
  );

  useEffect(() => {
    if (terminalId) {
      terminalDetailsService.send('READ_TERMINAL', { payload: { terminalId } });
    }
  }, [terminalId]);

  const identity = useMemo(() => {
    if (!readTerminal) return [];

    return [
      {
        key: readTerminal.terminal.reference.unique,
        value: readTerminal.terminal.reference.unique,
        label: 'Unique reference',
      },
      {
        key: readTerminal.terminal.reference.custom,
        value: readTerminal.terminal.reference.custom,
        label: 'Personal reference',
      },
    ];
  }, [readTerminal]);

  const markers = useMemo(() => {
    if (!readTerminal) return null;

    return {
      _id: readTerminal.terminal.reference.unique,
      label: `${readTerminal.terminal.reference.unique} - ${readTerminal.terminal.reference.custom}`,
      location: {
        lat: readTerminal.terminal.coordinates[0],
        lng: readTerminal.terminal.coordinates[1],
      },
      markerStyleId: 'default',
    };
  }, [readTerminal]);

  const statistics = useMemo(() => {
    if (!readTerminal) return [];

    return readTerminal.terminal.statistics.map(
      ({ label, value }: any, index: number) => ({
        key: `index-${label}`,
        label,
        value,
      }),
    );
  }, [readTerminal]);

  return (
    <Drawer
      className={className}
      data={
        <Col config={{ scrollY: true }}>
          {identity && (
            <>
              <Widget
                config={{
                  title: 'Identity',
                  shadow: false,
                  responsive: {
                    padding: false,
                  },
                }}
              >
                <Description
                  config={{ mode: 'line' }}
                  data={identity}
                ></Description>
              </Widget>
              <Space config={{ count: 3 }}></Space>
            </>
          )}
          {markers && (
            <>
              <Widget
                config={{
                  title: 'Location',
                  shadow: false,
                  responsive: {
                    padding: false,
                  },
                }}
              >
                <div style={{ height: '400px' }}>
                  <Maps
                    data={{
                      markers: [markers] as any,
                      selected: [],
                    }}
                    config={{
                      clickable: false,
                    }}
                  />
                </div>
              </Widget>
              <Space config={{ count: 3 }}></Space>
            </>
          )}

          {statistics && (
            <>
              <Widget
                config={{
                  title: 'Statistics',
                  shadow: false,
                  responsive: {
                    padding: false,
                  },
                }}
              >
                <Description
                  config={{ mode: 'line' }}
                  data={statistics}
                ></Description>
              </Widget>
              <Space config={{ count: 3 }}></Space>
            </>
          )}

          {peripheralsDataNode && (
            <>
              <Widget.Group
                config={{
                  title: 'Peripherals',
                  responsive: {
                    padding: false,
                  },
                }}
              >
                <Zone
                  config={{
                    zones: [['search'], ['details']],
                    columns: ['1fr'],
                    rows: ['min-content', '1fr'],
                    gap: {
                      y: 1,
                    },
                  }}
                >
                  <Zone.Area config={{ area: 'search' }}>
                    <SearchTools
                      className="machine-details-search-tools"
                      handleEvent={{
                        search: (value) => {
                          setSearchPeriph(value);
                        },
                      }}
                      config={{
                        actions: [
                          {
                            key: 'sort',
                            title: 'Sort',
                            type: 'single',
                            items: [
                              { value: 'name', label: 'Name' },
                              {
                                value: 'mostSeverity',
                                label: 'The most critical',
                              },
                              {
                                value: 'leastSeverity',
                                label: 'The least critical',
                              },
                            ],
                            defaultSelected: sortPeriph,
                            input: (value) => {
                              setSortPeriph(value);
                            },
                          },
                          {
                            key: 'filtered',
                            title: 'Filter',
                            type: 'multiple',
                            items: [
                              { value: 'out-of-order', label: 'HS' },
                              {
                                value: 'degraded',
                                label: 'Degraded',
                              },
                              {
                                value: 'action-requested',
                                label: 'Intervention request',
                              },
                              { value: 'info', label: 'Information' },
                            ],
                            defaultSelected: filterPeriph,
                            input: (value) => {
                              setFilterPeriph(value);
                            },
                          },
                        ],
                      }}
                    />
                  </Zone.Area>
                  <Zone.Area config={{ area: 'details' }}>
                    <Widget
                      config={{
                        responsive: {
                          padding: false,
                        },
                      }}
                    >
                      <ListFlatInfo<any>
                        handleEvent={{
                          paging: (paging) => {
                            // setPaging(paging);
                          },
                          click: (value) => {},
                        }}
                        config={{
                          columns: [
                            { key: 'present', size: 'min-content' },
                            { key: 'identity', size: '1fr' },
                          ],
                          pagination: 'scroll',
                        }}
                        data={peripheralsDataNode}
                      ></ListFlatInfo>
                    </Widget>
                  </Zone.Area>
                </Zone>
              </Widget.Group>
            </>
          )}
        </Col>
      }
      config={{
        size: isDesktop ? 'medium' : 'xxlarge',
        placement: isDesktop ? 'right' : 'bottom',
        isOpen: isOpen,
      }}
      handleEvent={{
        close: () => close?.(),
      }}
    >
      {children}
    </Drawer>
  );
};
