import packageJSON from '../package.json';

const config = {
  VERSION_FRONT: packageJSON?.version,
  ENV: process.env.REACT_APP_ENV || 'LOCAL',
  BFF_HOST:
    process.env.REACT_APP_HOST_BFF_PRESTO_PMS || 'http://localhost:3847',
  BFF_WS_HOST: process.env.REACT_APP_HOST_BFF_EVENTS || 'ws://localhost:3333',
  BFF_TOKEN:
    process.env.REACT_APP_BFF_PRESTO_PMS_TOKEN ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LXByZXN0by1zY2FuIiwiYXBwVmVyc2lvbiI6InYxIiwiaWF0IjoxNzAxOTUzMTgzfQ.SEGl2NFy573tj5Ma1M2Ddy6ToHxAWZCWGoz4mnL-YC4',
  GOOGLE_MAPS_API_KEY:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyDM6d0A8LcUWAqN94vuHB_louCYp1gA1IU',
};

export default config;
