import { Size } from '@gimlite/watermelon/types/component.type'
import { type ResumeAlarmsSeverity } from '../../static/resumeAlarmsStats'
import { ResumeTransactionsSeverity } from '../../static/resumeTransactionsStats'
import './resume-stats.component.scss'

export declare namespace ResumeStatsType {
  type Props = {
    className?: string
    data?: number
    config: {
      size?: Extract<Size, 'xsmall' | 'medium'>
      severity: ResumeAlarmsSeverity | ResumeTransactionsSeverity
    }
  }
}

export const ResumeStats = ({
  data,
  config: { severity, size = 'medium' },
  className = ''
}: ResumeStatsType.Props) => {
  return (
    <div
      className={`
      resume-stats
      resume-stats--size--${size}
      resume-stats--severity--${severity} 
      ${className}
      `}
    >
      <span>{data}</span>
    </div>
  )
}
