import MainUse from './main';
import { alarmsService } from './pages/alarms/alarms.machine';
import { AlarmsView } from './pages/alarms/view/alarms.view';
import { HomePage } from './pages/home';
import { homeService } from './pages/home/home.machine';
import { loginService } from './pages/login/login.machine';
import { LoginPage } from './pages/login/view/login.page';
import { MapsView } from './pages/maps/view/maps.page';
import { SystemsService } from './pages/systems/systems.machine';
import { SystemsView } from './pages/systems/view/systems.view';
import { terminalMachine } from './pages/terminals/terminals.machine';
import { TerminalsPage } from './pages/terminals/view/terminals.page';
import { transactionsService } from './pages/transactions/transactions.machine';
import { TransactionsView } from './pages/transactions/view/transactions.page';

export const guestPathToPage = [
  {
    path: '/',
    Component: LoginPage,
    machine: loginService,
    children: [
      {
        path: '/login',
        Component: LoginPage,
      },
      {
        path: '*',
        Component: LoginPage,
      },
    ],
  },
];

export const userPathToPage = [
  {
    path: '/',
    Component: MainUse,
    children: [
      {
        path: '/',
        Component: HomePage,
        machine: homeService,
      },
      {
        path: '/terminals',
        Component: TerminalsPage,
        machine: terminalMachine,
      },
      {
        path: '/maps',
        Component: MapsView,
        machine: terminalMachine,
      },
      {
        path: '/systems',
        Component: SystemsView,
        machine: SystemsService,
      },
      {
        path: '/transactions',
        Component: TransactionsView,
        machine: transactionsService,
      },
      {
        path: '/alarms/all',
        Component: (value: { machine: any; store: any }) => {
          return AlarmsView({ ...value, type: '*' });
        },
        machine: alarmsService,
      },
      {
        path: '/alarms/modem',
        Component: (value: { machine: any; store: any }) => {
          return AlarmsView({ ...value, type: 'modem' });
        },
        machine: alarmsService,
      },
      {
        path: '/alarms/collect',
        Component: (value: { machine: any; store: any }) => {
          return AlarmsView({ ...value, type: 'collect' });
        },
        machine: alarmsService,
      },
      {
        path: '/alarms/printer',
        Component: (value: { machine: any; store: any }) => {
          return AlarmsView({ ...value, type: 'printer' });
        },
        machine: alarmsService,
      },
      {
        path: '/alarms/energy',
        Component: (value: { machine: any; store: any }) => {
          return AlarmsView({ ...value, type: 'energy' });
        },
        machine: alarmsService,
      },
    ],
  },
];
