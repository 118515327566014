import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { ResumeStats } from '../../../components/resume-stats/resume-stats.component';
import { resumeAlarmsStatsSortFn } from '../../../static/resumeAlarmsStats';

export const resumeAlarms = (context, data) => {
  return {
    list: data.resume
      .sort((a, b) => resumeAlarmsStatsSortFn(a.effect, b.effect))
      .reduce(
        (acc, { count, effect, label }, index) => [
          ...acc,
          {
            _id: `resume-item-${index}`,
            items: {
              present: (
                <ResumeStats
                  data={count}
                  config={{ severity: effect }}
                ></ResumeStats>
              ),
              identity: <Blurb data={{ title: label }}></Blurb>,
            },
          },
        ],
        [],
      ),
    paging: { count: 1, current: 1, limit: 1 },
  };
};
