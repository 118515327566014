import { gql } from '@apollo/client';

export const resumeAlarms = gql`
  query resumeAlarms($type: String, $zoneId: String) {
    resumeAlarms(type: $type, zoneId: $zoneId) {
      resume {
        count
        effect
        label
      }
    }
  }
`;
