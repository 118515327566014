import { gql } from '@apollo/client';

export const searchAlarms = gql`
  query searchAlarms(
    $type: String
    $page: Int!
    $limit: Int!
    $order: String
    $effects: [String!]
    $search: String
    $zoneId: String
  ) {
    searchAlarms(
      type: $type
      page: $page
      limit: $limit
      order: $order
      effects: $effects
      search: $search
      zoneId: $zoneId
    ) {
      list {
        _id
        effect
        label
        type
        logDate
        terminalId
        reference {
          unique
          custom
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
