import { RouterType } from '@gimlite/router';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo } from 'react';
import { searchSystemsDataNode } from '../../../data/system';

export const SystemsView = ({ machine }: RouterType.Legacy) => {
  const searchSystems = useSelector(
    machine,
    ({ context }: any) => context.search,
  );

  const { isDesktop } = useDevice();

  useEffect(() => {
    // dispatch('SEARCH_SYSTEMS', {
    //   payload: {},
    // });
  }, []);

  const resumeNode = useMemo(() => {
    return (
      <Widget
        config={{
          title: 'System status',
          subtitle: 'Summary of states of life',
          responsive: {
            padding: false,
          },
        }}
      >
        <ListFlatInfo<any>
          data={{
            list: searchSystemsDataNode,
          }}
          config={{
            columns: [
              { key: 'present', size: 'min-content' },
              { key: 'identity', size: '1fr' },
            ],
            yMax: isDesktop ? 'initial' : 'min-content',
            pagination: 'none',
          }}
        ></ListFlatInfo>
      </Widget>
    );
  }, [isDesktop]);

  return (
    <Page>
      {isDesktop ? (
        <Zone
          config={{
            gap: {
              x: 2,
            },
            zones: [['bilan']],
            rows: ['1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'bilan' }}>{resumeNode}</Zone.Area>
        </Zone>
      ) : (
        <Col>{resumeNode}</Col>
      )}
    </Page>
  );
};
