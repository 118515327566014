import { useRootStore } from '@gimlite/watermelon';
import { Drawer } from '@gimlite/watermelon/components/drawer/drawer.component';
import {
  Icon,
  IconType,
} from '@gimlite/watermelon/components/icon/icon.component';
import { Menu } from '@gimlite/watermelon/components/menu/menu.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { useDevice } from '@gimlite/watermelon/hook/useDevice.hook';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import './select-zone.component.scss';
import { zoneDetailsService } from './select-zone.machine';

class Static {
  static findZoneCountRecursive = (
    zones: SelectZoneType.Static.ZoneFormatted[],
    selected: string,
  ): number => {
    for (const { key, count, items = null } of zones) {
      if (key === selected) return count;
      if (items) {
        const tryWithItem = Static.findZoneCountRecursive(items, selected);
        if (tryWithItem) return tryWithItem;
      }
    }

    return 0;
  };

  static findZoneLabelRecursive = (
    zone: SelectZoneType.Static.ZoneFormatted,
    selected: string,
  ): string | null => {
    if (zone.key === selected) return zone.label;

    if (zone.items) {
      const foundLabel = zone.items
        .map((item) => Static.findZoneLabelRecursive(item, selected))
        .find((label) => label !== null);
      if (foundLabel) {
        return `${zone.label} ${foundLabel}`;
      }
    }

    return null;
  };

  static findZoneTitle = (
    zones: SelectZoneType.Static.ZoneFormatted[],
    selected: string,
  ): string => {
    const text = zones
      .map((zone) => Static.findZoneLabelRecursive(zone, selected))
      .find(Boolean);

    const count = Static.findZoneCountRecursive(zones, selected);

    return text ? `${text} (${count})` : '...';
  };

  static zoneNodeRecursive = (zones: SelectZoneType.Static.ZoneFormatted[]) => {
    return zones.map(({ count, icon, key, label, items }) => {
      const result: SelectZoneType.Static.ZoneBuild = {
        key,
        label,
        icon,
        extra: (
          <Write
            data={{ item: `${count}` }}
            config={{ mode: 'value-small' }}
          ></Write>
        ),
      };

      if (items && Array.isArray(items) && items.length > 0)
        result.items = Static.zoneNodeRecursive(items);

      return result;
    });
  };
}

export declare namespace SelectZoneType {
  type Props = {
    className?: string;
  };

  namespace Static {
    type Site = {
      _id: string;
      label: string;
      terminalId: string;
    };

    type Zone = {
      _id: string;
      label: string;
      sites: SelectZoneType.Static.Site[];
    };

    type User = {
      _id: string;
      clients: SelectZoneType.Static.Zone[];
      groups: SelectZoneType.Static.Zone[];
    };

    type ZoneFormatted = {
      key: string;
      label: string;
      icon: IconType.Config.Type;
      count: number;
      items?: ZoneFormatted[];
    };

    type ZoneBuild = {
      key: string;
      label: string;
      icon: IconType.Config.Type;
      extra: React.ReactNode;
      items?: ZoneBuild[];
    };
  }
}

export const SelectZone = observer(
  ({ className = '' }: SelectZoneType.Props) => {
    const { isDesktop } = useDevice();

    const { AuthStore } = useRootStore();
    const me = AuthStore.me as SelectZoneType.Static.User | undefined;

    const selected = useSelector(
      zoneDetailsService,
      ({ context }: { context: any }) => context.selectedZone,
    );

    const zonesFormatted = useMemo(() => {
      if (!me) return [];

      const zonesBase = [];

      if (me?.clients)
        zonesBase.push({
          title: 'Official zone',
          zone: me.clients,
          type: 'client',
        });
      if (me?.groups)
        zonesBase.push({
          title: 'Custom zone',
          zone: me.groups,
          type: 'group',
        });

      const zones = zonesBase.map(({ title, zone, type }) => ({
        type,
        title,
        zone: zone.reduce(
          (
            acc: SelectZoneType.Static.ZoneFormatted[],
            { _id: idZone, label: labelZone, sites },
          ) => {
            return [
              ...acc,
              {
                key: idZone,
                label: labelZone,
                icon: 'customPieFull' as IconType.Config.Type,
                count: sites.length,
                items: sites.reduce(
                  (
                    acc: SelectZoneType.Static.ZoneFormatted[],
                    { _id: idSubZone, label: labelSubZone, terminalId },
                    index: number,
                  ) => {
                    const newAcc = acc;

                    const tryFindTagIndex = newAcc.findIndex(
                      ({ key: keyRegister }) => keyRegister === idSubZone,
                    );

                    if (tryFindTagIndex !== -1) {
                      newAcc[tryFindTagIndex].count =
                        newAcc[tryFindTagIndex].count + 1;
                      return newAcc;
                    }

                    return [
                      ...acc,
                      {
                        key: idSubZone,
                        label: labelSubZone,
                        icon: 'customPiePartial' as IconType.Config.Type,
                        count: 1,
                      },
                    ];
                  },
                  [],
                ),
              },
            ];
          },
          [],
        ),
      }));

      const tryFindZoneClientIndex = zones.findIndex(
        ({ type }) => type === 'client',
      );

      if (tryFindZoneClientIndex !== -1) {
        zones[tryFindZoneClientIndex].zone.splice(0, 0, {
          key: 'global',
          label: 'Global',
          icon: 'customPieFull',
          count: zones[tryFindZoneClientIndex].zone.reduce(
            (acc, { count }) => acc + count,
            0,
          ),
        });
      }

      return zones;
    }, [me]);

    const zonesBuild = useMemo(() => {
      if (!zonesFormatted) return [];

      return zonesFormatted.map(({ title, zone }) => ({
        title,
        zone: Static.zoneNodeRecursive(zone),
      }));
    }, [zonesFormatted]);

    const navTitle = useMemo(
      () =>
        Static.findZoneTitle(
          zonesFormatted.reduce(
            (acc: SelectZoneType.Static.ZoneFormatted[], { zone }) => [
              ...acc,
              ...zone,
            ],
            [],
          ),
          selected,
        ),
      [selected, zonesFormatted],
    );

    return (
      <Drawer
        config={{
          placement: isDesktop ? 'right' : 'bottom',
          size: isDesktop ? 'medium' : 'xlarge',
        }}
        data={
          <Widget
            config={{
              shadow: false,
              title: 'Select a zone',
              subtitle: 'Find all the zones available for you',
              responsive: {
                padding: false,
              },
            }}
          >
            {zonesBuild.map(({ title, zone }, index) => {
              return (
                <div key={index}>
                  <Menu
                    handleEvent={{
                      navigation: (key) => {
                        zoneDetailsService.send('REGISTER_ZONE', {
                          payload: { key },
                        });
                      },
                    }}
                    config={{ mode: 'horizontal' }}
                    data={{
                      selected: {
                        value: selected,
                      },
                      title: title,
                      items: zone,
                    }}
                  />
                </div>
              );
            })}
          </Widget>
        }
      >
        <button className={`select-zone ${className}`}>
          <Write
            data={{ item: navTitle }}
            config={{ mode: 'title-small', color: 'primary-over', wrap: false }}
          ></Write>
          <Icon
            className="select-zone__icon"
            config={{ type: 'faChevronDownSolid', color: 'primary-over' }}
          ></Icon>
        </button>
      </Drawer>
    );
  },
);
