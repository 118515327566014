import type { RouterType } from '@gimlite/router';
import { dispatch, useNavigate } from '@gimlite/router';
import { useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Checkbox } from '@gimlite/watermelon/components/checkbox/checkbox.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Header } from '@gimlite/watermelon/components/header/header.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { toCapitalizeCase } from '@gimlite/watermelon/functions/string.function';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LoginJPG from '../../../assets/images/login.jpg';
import './login.page.scss';

export const LoginPage = observer(({ machine }: RouterType.Legacy) => {
  const navigate = useNavigate();
  const { AuthStore } = useRootStore();
  const { lang, t }: any = useTranslation();

  const error = useSelector(machine, ({ context }: any) => context.error);
  const token = useSelector(machine, ({ context }: any) => context.token);
  const contract = useSelector(machine, ({ context }: any) => context.contract);
  const [step, setStep] = useState(0);
  const shouldResetPassword = useSelector(
    machine,
    ({ context }: any) => context.shouldResetPassword,
  );
  const user = useSelector(machine, ({ context }: any) => context.user);

  const errorMemo = useMemo(
    () => (error ? t(error, { '[min]': '6' }) : undefined),
    [error, lang],
  );

  //! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
  //******* START ************/
  useEffect(() => {
    setStep(() => 0);
    dispatch('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-prestoscan-global' },
    });
  }, []);
  //******* STOP ************/

  const accessToOffice = useCallback(() => {
    setStep(() => 0);
    dispatch('RESET', {});
    AuthStore.login(token);
    navigate('/');
  }, [token]);

  useEffect(() => {
    if (shouldResetPassword) {
      setStep(1);
    } else if (token && shouldResetPassword === false) {
      accessToOffice();
    }
  }, [token, shouldResetPassword]);

  useEffect(() => {
    if (contract) accessToOffice();
  }, [contract]);

  return (
    <div className="login">
      {/*! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1) }*/}
      {/******* START ************/}
      <Header config={{ logo: IEM(), position: 'absolute' }} />
      {/******* STOP ************/}
      <div className="login__back">
        <div className="login__back__mask"></div>
        <img className="login__back__image" src={LoginJPG} alt="" />
      </div>
      <Widget
        className="loginWidget"
        config={{
          icon: 'faUserSolid',
          position: 'absolute',
          title: step === 0 ? t('personalSpace') : t('changeYourPassword'),
        }}
      >
        <Form
          className="loginForm"
          config={{ validateOnChange: false }}
          handleEvent={{
            change: (value: Record<string, string | number>) => {
              machine.send('UPDATE_USER', value);
              machine.send('RESET_ERROR', {});
            },
          }}
        >
          {step === 0 ? (
            <>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'username',
                  label: `${toCapitalizeCase(t('email'))}`,
                  way: 'vertical',

                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    height: 'large',
                    width: 'full',
                    placeholder: t('emailAddress'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'password',
                  label: t('password'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('password'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 2 }} />
              <Button
                handleEvent={{
                  click: () => {
                    machine.send('LOGIN');
                  },
                }}
                config={{ color: 'primary', text: t('signIn') }}
              />
              <Space config={{ count: 0.5 }} />
              {errorMemo && (
                <Write
                  data={{ item: errorMemo }}
                  config={{
                    mode: 'key-small-light',
                  }}
                />
              )}
            </>
          ) : undefined}
          {step === 1 ? (
            <>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'newPassword',
                  label: t('newPassword'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('newPassword'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'confirmNewPassword',
                  label: t('confirmationOfNewPassword'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('confirmationOfNewPassword'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1 }} />
              <div className="loginFormPart">
                <Write
                  data={{ item: `* ${t('requiredFields')}` }}
                  config={{
                    color: 'error',
                    mode: 'value-small',
                  }}
                />
              </div>
              <Space config={{ way: 'vertical', count: 2 }} />
              <div className="loginFormPart">
                <Write
                  data={{ item: t('INFO_ABOUT_PERSONAL_INFO') }}
                  config={{
                    mode: 'value-small',
                  }}
                />
              </div>
              <Space config={{ way: 'vertical', count: 2 }} />

              <div className="loginFormPart">
                <Form.Item
                  className="loginFormCheckbox"
                  config={{
                    name: 'acceptContract',
                    rules: [{ required: true }],
                  }}
                >
                  <Checkbox />
                </Form.Item>
                <Space config={{ way: 'horizontal', count: 1.5 }} />
                <Write
                  className="loginFormText"
                  data={{ item: t('CONFIRM_INFO_ABOUT_PERSONAL_INFO') }}
                  config={{
                    mode: 'value-small',
                  }}
                />
              </div>

              <Space config={{ way: 'vertical', count: 2 }} />
              <Button
                handleEvent={{
                  click: () => {
                    if (!user?.newPassword || !user?.confirmNewPassword) {
                      machine.send('SHOW_ERROR', {
                        error: 'fillNewPasswordAndConfirm',
                      });
                    } else if (user?.newPassword?.length < 6) {
                      machine.send('SHOW_ERROR', {
                        error: 'passwordTooShort',
                      });
                    } else if (user?.newPassword !== user?.confirmNewPassword) {
                      machine.send('SHOW_ERROR', {
                        error: 'identicalPassword',
                      });
                    } else if (!user?.acceptContract) {
                      machine.send('SHOW_ERROR', {
                        error: 'acceptContract',
                      });
                    } else {
                      machine.send('RESET_PASSWORD');
                    }
                  },
                }}
                config={{ color: 'primary', text: t('confirm') }}
              />
              <Space config={{ way: 'vertical', count: 0.5 }} />

              {errorMemo && (
                <Write
                  data={{ item: errorMemo }}
                  config={{
                    color: 'error',
                    mode: 'key-small-light',
                  }}
                />
              )}
            </>
          ) : undefined}
        </Form>
      </Widget>
    </div>
  );
});
