import { Icon } from '@gimlite/watermelon/components/icon/icon.component'
import { ResumeTransactionsSeverity } from '../../static/resumeTransactionsStats'
import './resume-health.component.scss'

export declare namespace ResumeHealthType {
  type Props = {
    className?: string
    config: {
      severity: ResumeTransactionsSeverity
    }
  }
}

export const ResumeHealth = ({
  config: { severity },
  className = ''
}: ResumeHealthType.Props) => {
  return (
    <div
      className={`
      resume-health
      resume-health--severity--${severity} 
      ${className}
      `}
    >
      <Icon
        config={{
          size: 'medium',
          color: severity === 'failure' ? 'error' : 'success',
          type: severity === 'failure' ? 'faXmarkSolid' : 'faCheckSolid'
        }}
      ></Icon>
    </div>
  )
}
